import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navigation.css";

const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation(); // Usamos useLocation para obtener la ruta actual

  return (
    <nav id="navigation">
      <div className="container">
        <div id="responsive-nav">
          <ul className={`main-nav ${isMenuOpen ? "active" : ""}`}>
            <li className={location.pathname === "/" ? "active" : ""}>
              <Link to="/">Home</Link>
            </li>
            <li className={location.pathname === "/productCatalog" ? "active" : ""}>
              <Link to="/productCatalog">Catálogo</Link>
            </li>
          </ul>

        </div>
      </div>
    </nav>
  );
};

export default Navigation;
