import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Cart.css'; // Asegúrate de que este archivo CSS existe

const Cart = () => {
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [total, setTotal] = useState(0);
    const [userType, setUserType] = useState('');

    const productos = [
        {
            id_producto: 1,
            nombre: "Cables usb to lightning 1 metro",
            precio: 20.00,
            precio_mayorista: 14.00,
        },
        {
            id_producto:2,
            nombre: "Cable tipo C to Lightning",
            precio: 25.00,
            precio_mayorista: 8.50,
        },
        {
            id_producto: 12,
            nombre: "Cables USB to lightning 2 metros",
            precio: 45.00,
            precio_mayorista: 13.00
        },
        {
            id_producto: 3,
            nombre: "Combo de 20w APPLE",
            precio: 30.00,
            precio_mayorista: 18.00
        },
        {
            id_producto: 4,
            nombre: "Cubo 20w calidad original",
            precio: 45.00,
            precio_mayorista: 19.00
        },
        {
            id_producto: 6,
            nombre: "AirPods Pro 2 calidad original",
            precio: 99.00,
            precio_mayorista: 60.00
        },
        {
            id_producto: 7,
            nombre: "Samsung Combo 25w",
            precio: 30.00,
            precio_mayorista: 18.00
        },
        {
            id_producto: 8,
            nombre: "Samsung Combo 45w",
            precio: 50.00,
            precio_mayorista: 24.00
        }
    ]

    const fetchCartItems = async () => {
        const token = localStorage.getItem('token');
        const tipoUsuario = localStorage.getItem('tipo_usuario');
        setUserType(tipoUsuario);

        let cartData = [];
        if (token) {
            // Usuario autenticado: Obtener carrito desde el backend
            try {
                const response = await axios.get('https://tt-backend-34ls.onrender.com/api/cart', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                cartData = response.data;
            } catch (error) {
                console.error('Error al obtener el carrito desde el backend:', error);
                setError('No se pudo cargar el carrito. Intenta nuevamente.');
            }
        } else {
            // Usuario no autenticado: Obtener carrito desde localStorage
            cartData = JSON.parse(localStorage.getItem('cart')) || [];
        }

        // Asignar precios desde la lista local
        cartData.forEach((item) => {
            const producto = productos.find(p => p.id_producto === item.id_producto);
            if (producto) {
                item.precio = producto.precio;
                item.precio_mayorista = producto.precio_mayorista;
            }

            // Aplicar precio mayorista si la cantidad es >= 3
            if (item.cantidad >= 3) {
                item.precio = item.precio_mayorista || item.precio;
            }
        });

        setCartItems(cartData);
        setLoading(false);
    };

    // Calcular el total
    useEffect(() => {
        const calculateTotal = () => {
            let newTotal = 0;
            cartItems.forEach((item) => {
                const itemQuantity = item.cantidad || 1;
                const priceToUse = item.cantidad >= 3 && (userType === 'normal' || !localStorage.getItem('token'))
                    ? item.precio_mayorista || item.precio
                    : item.precio;

                newTotal += priceToUse * itemQuantity;
            });
            setTotal(newTotal);
        };
        calculateTotal();
    }, [cartItems, userType]);

    const handleQuantityChange = async (id_producto, newQuantity) => {
        if (!id_producto || newQuantity < 1) {
            console.error("Invalid product ID or quantity");
            return;
        }

        const token = localStorage.getItem('token');
        if (!token) {
            let cart = JSON.parse(localStorage.getItem('cart')) || [];
            const itemIndex = cart.findIndex((item) => item.id_producto === id_producto);
        
            if (itemIndex !== -1) {
                // Actualiza la cantidad y aplica precio mayorista si corresponde
                cart[itemIndex].cantidad = newQuantity;
        
                const producto = productos.find(p => p.id_producto === id_producto);
                if (producto && newQuantity >= 3) {
                    cart[itemIndex].precio_mayorista = producto.precio_mayorista;
                }
        
                // Guarda el carrito en localStorage
                localStorage.setItem('cart', JSON.stringify(cart));
        
                // Asegúrate de actualizar el estado en React
                setCartItems(cart); // Usa directamente el carrito actualizado
                console.log('Estado del carrito en React:', cart);
            } else {
                console.error('El producto no se encontró en el carrito.');
            }
            return;
        }
        

        // Usuario autenticado: Actualizar en backend
        try {
            await axios.put(
                `https://tt-backend-34ls.onrender.com/api/cart/${id_producto}`,
                { cantidad: newQuantity },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            fetchCartItems();
        } catch (error) {
            console.error('Error al actualizar la cantidad:', error.response ? error.response.data : error.message);
            setError('No se pudo actualizar la cantidad');
        }
    };

    const handleRemoveItem = async (id_producto) => {
        if (!id_producto) {
            console.error("Product ID is undefined");
            return;
        }

        const token = localStorage.getItem('token');
        if (!token) {
            let cart = JSON.parse(localStorage.getItem('cart')) || [];
            cart = cart.filter((item) => item.id_producto !== id_producto);
            localStorage.setItem('cart', JSON.stringify(cart));
            setCartItems(cart);
            return;
        }

        try {
            await axios.delete(
                `https://tt-backend-34ls.onrender.com/api/cart/${id_producto}`,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setCartItems((prevItems) =>
                prevItems.filter((item) => item.id_producto !== id_producto)
            );
        } catch (error) {
            console.error('Error al eliminar el producto del carrito:', error.response ? error.response.data : error.message);
            alert('No se pudo eliminar el producto. Inténtalo nuevamente.');
        }
    };

    useEffect(() => {
        fetchCartItems();
    }, []);

    if (loading) return <div className="loading">Cargando carrito...</div>;
    if (error) return <div className="error">{error}</div>;

    return (
        <div className="cart-container">
            <table className="cart-table">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Precio</th>
                        <th>Cantidad</th>
                        <th>Acción</th>
                    </tr>
                </thead>
                <tbody>
                    {cartItems.map((item) => (
                        <React.Fragment key={item.id_producto}>
                            <tr>
                                <td>{item.nombre}</td>
                                <td>
                                    {item.cantidad >= 3 && (userType === 'normal' || !localStorage.getItem('token'))
                                        ? Number(item.precio_mayorista || item.precio).toFixed(2)
                                        : Number(item.precio || 0).toFixed(2)
                                    }
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        value={item.cantidad || 1}
                                        min="1"
                                        onChange={(e) =>
                                            handleQuantityChange(
                                                item.id_producto,
                                                parseInt(e.target.value)
                                            )
                                        }
                                        className="quantity-input"
                                    />
                                </td>
                                <td>
                                    <button
                                        className="remove-button"
                                        onClick={() => handleRemoveItem(item.id_producto)}
                                    >
                                        Eliminar
                                    </button>
                                </td>
                            </tr>
                            {item.cantidad >= 3 && (userType === 'normal' || !localStorage.getItem('token')) && (
                                <tr>
                                    <td colSpan="4" className="bulk-message">
                                        Se está aplicando el precio al por mayor, ¡disfruta!
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
            <div className="total">
                <strong>Total: S/.{total.toFixed(2)}</strong>
            </div>
        </div>
    );
};

export default Cart;