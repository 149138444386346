import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Auth.css';
import { useAuth } from '../context/AuthContext';

const Login = ({ onOpenRegister }) => {
  const [formData, setFormData] = useState({
    nombre_usuario: '', // Cambié el email por nombre_usuario
    contraseña: '',
  });
  const [error, setError] = useState('');
  const { login } = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const error = await login(formData); // Asegúrate de que 'login' maneja el nombre_usuario
    if (error) {
      setError(error);
    } else {
      setError('');
      window.location.href = '/'; // Redirige al usuario después de un inicio de sesión exitoso
    }
  };

  return (
    <div className="form-container">
      <p className="title-auth">Iniciar Sesión</p>
      {error && <p className="error-message">{error}</p>}
      <form className="form" onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="nombre_usuario">Nombre de Usuario</label> {/* Cambié a nombre_usuario */}
          <input
            type="text"
            name="nombre_usuario"  // Cambié aquí también
            id="nombre_usuario"   // Y aquí
            placeholder="Introduce tu nombre de usuario"
            value={formData.nombre_usuario}  // Usando nombre_usuario en lugar de email
            onChange={handleChange}
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">Contraseña</label>
          <input
            type="password"
            name="contraseña"
            id="contraseña"
            placeholder="Introduce tu contraseña"
            value={formData.contraseña}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="sign">
          Iniciar Sesión
        </button>
      </form>
      <p className="signup">
        Aún no tienes una cuenta?{' '}
        <Link to="/register" onClick={onOpenRegister}>
          Regístrate
        </Link>
      </p>
    </div>
  );
};

export default Login;
