import React from 'react';
import './ProductSection.css'; 

const ProductSection = () => {
    // Lista de productos con las URLs de las imágenes en el servidor remoto
    const products = [
        {
            name: "Cargador",
            imageUrl: "https://tt-backend-34ls.onrender.com/images/cargador.png", // URL remota
        },
        {
            name: "Cargador Inalámbrico",
            imageUrl: "https://tt-backend-34ls.onrender.com/images/CargadorInalambrico.png", // URL remota
        },
        {
            name: "Airpods",
            imageUrl: "https://tt-backend-34ls.onrender.com/images/Airpods.png", // URL remota
        },
    ];

    return (
        <div className="section">
            <div className="container-product">
                <div className="row">
                    {/* Mostrar los productos específicos */}
                    {products.map((product, index) => (
                        <div className="col" key={index}>
                            <div className="shop">
                                <div className="shop-img">
                                    <img src={product.imageUrl} alt={product.name} />
                                    <a href="#" className="cta-btn">
                                        Shop now <i className="fa fa-arrow-circle-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProductSection;
