// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Estilos globales */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  main {
    flex: 1 1;
    text-align: center;
  }
  
  h1 {
    font-size: 3rem;
    color: #1B263B;
  }
  `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,gCAAgC;IAChC,SAAS;IACT,UAAU;IACV,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,iBAAiB;EACnB;;EAEA;IACE,SAAO;IACP,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,cAAc;EAChB","sourcesContent":["/* Estilos globales */\nbody {\n    font-family: 'Arial', sans-serif;\n    margin: 0;\n    padding: 0;\n    background-color: #ffffff;\n  }\n  \n  .App {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n  }\n  \n  main {\n    flex: 1;\n    text-align: center;\n  }\n  \n  h1 {\n    font-size: 3rem;\n    color: #1B263B;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
