import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './ProductDetails.css';
import ProductFeatures from './ProductFeatures';


const ProductDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cantidad, setQuantity] = useState(1); // Nuevo estado para manejar la cantidad

    useEffect(() => {
        const fetchProduct = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(
                    `https://tt-backend-34ls.onrender.com/api/products/${id}`,
                    {
                        headers: {
                            Authorization: token ? `Bearer ${token}` : '',
                        },
                    }
                );
                const productWithImageUrl = {
                    ...response.data,
                    imageUrl: `https://tt-backend-34ls.onrender.com${response.data.image_path}`,
                };
                setProduct(productWithImageUrl);
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener el producto:', error);
                setError(error);
                setLoading(false);
            }
        };

        fetchProduct();
    }, [id]);

    const handleAddToCart = async (productId) => {
        const token = localStorage.getItem("token");
        const cartItem = { productId, cantidad };

        if (!token) {
            let cart = JSON.parse(localStorage.getItem("cart")) || [];
            const existingItem = cart.find((item) => item.productId === productId);

            if (existingItem) {
                existingItem.cantidad += cantidad;
            } else {
                try {
                    const response = await axios.get(
                        `https://tt-backend-34ls.onrender.com/api/products/${productId}`
                    );
                    const productDetails = response.data;
                    cart.push({ ...cartItem, ...productDetails });
                    localStorage.setItem("cart", JSON.stringify(cart));
                    alert("Producto añadido al carrito");
                } catch (error) {
                    console.error("Error al obtener los detalles del producto", error);
                    alert("Hubo un error al intentar obtener los detalles del producto.");
                }
            }

            return;
        }

        try {
            const response = await axios.post(
                "https://tt-backend-34ls.onrender.com/api/cart",
                cartItem,
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.status === 200) {
                alert("Producto añadido al carrito.");
            }
        } catch (error) {
            console.error("Error al agregar producto al carrito", error);
            alert("Hubo un error al intentar agregar el producto al carrito.");
        }
    };

    const handleBuyNow = () => {
        alert("Redirigiendo a la página de compra...");
        navigate('/checkout'); // Redirige a la página de checkout
    };

    if (loading) return <div className="loading">Cargando detalles del producto...</div>;
    if (error) return <div className="error">Error al cargar los detalles del producto</div>;

    return (
        <div className="product-detail-container">
            <div className="product-card-details">
                <div className="product-image">
                    <img src={product.imageUrl} alt={product.nombre} />
                </div>
                <div className="product-info">
                    <h1 className="product-title">{product.nombre}</h1>
                    <p className="product-description">{product.descripcion}</p>
                    <div className="product-price">
                        {product.precio ? `S/.${product.precio}` : 'Precio no disponible'}
                    </div>
                    {product.esPrecioPersonalizado && (
                        <p className="price-note">Precio especial para usuarios premium</p>
                    )}
                    <div className="quantity-control">
                        <button
                            className="quantity-btn"
                            onClick={() => setQuantity((prev) => Math.max(1, prev - 1))}
                        >
                            -
                        </button>
                        <span className="quantity-display">{cantidad}</span>
                        <button
                            className="quantity-btn"
                            onClick={() => setQuantity((prev) => prev + 1)}
                        >
                            +
                        </button>
                    </div>
                    <div className="action-buttons">
                        <button
                            className="add-to-cart-btn-details"
                            onClick={() => handleAddToCart(product.id_producto)}
                        >
                            Agregar al carrito
                        </button>
                        <button className="buy-now-btn" onClick={handleBuyNow}>
                            Comprar
                        </button>
                    </div>
                </div>
            </div>
        </div>

    );
};


export default ProductDetail;
