import React from 'react';
import { Link } from "react-router-dom";
import './Header.css';
import { useAuth } from '../context/AuthContext'; // Asegúrate de tener un contexto de autenticación

function Header() {
  const { user, logout } = useAuth(); // user contiene la información del usuario autenticado

  const handleLogout = () => {
    logout();
    window.location.href = '/'; // Redirige a la página de inicio
  };

  return (
    <div id="header">
      <div className="container">
        <div className="logo">
          <Link to="/">Tienda Trending</Link>
        </div>

        <div className="search-bar">
          <form>
            <select className="input-select">
              <option value="0">Categorías</option>
              <option value="1">Accesorios</option>
              <option value="2">Audífonos</option>
            </select>
            <input className="input" placeholder="Búsqueda" type="text" />
            <button className="search-btn">Buscar</button>
          </form>
        </div>

        <div className="account">
          <div className="cart">
            <Link to="/carrito" className="cart-toggle">
              <i className="fa fa-shopping-cart"></i>
              <span>Carrito</span>
            </Link>
          </div>

          {user ? ( // Si el usuario está autenticado
            <div className="user-info">
              <span className="user-name">Hola, {user.nombre_usuario}</span>
              <button onClick={handleLogout} className="logout-btn">
                <i className="fa fa-sign-out"></i>
                <span>Cerrar sesión</span>
              </button>
            </div>
          ) : ( // Si el usuario no está autenticado
            <div className="login">
              <Link to="/login" className="login-link">
                <i className="fa fa-user-circle"></i>
                <span>Iniciar sesión</span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
