import React from 'react';
import './Footer.css'; // Estilos del pie de página
import fondo from "../assets/logo.png"; // Asegúrate de tener el logo disponible

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="logo-and-social">
          {/* Logo y Nombre Tienda Trending */}
          <div className="footer-logo">
            <img src={fondo} alt="Logo Tienda Trending" className="footer-logo-img" />
            <span className="footer-logo-text">Tienda Trending</span>
          </div>

          {/* Íconos sociales */}
          <div className="social-icons">
            <a 
              href="https://www.instagram.com/tiendatrendingperu/" 
              className="social-icon instagram" 
              title="Instagram" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a 
              href="https://www.tiktok.com/@tiendatrendingperu?_t=8rVp72WS0PA&_r=1" 
              className="social-icon tiktok" 
              title="TikTok" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <i className="fab fa-tiktok"></i>
            </a>
            <a 
              href="https://www.facebook.com/tiendatrending.c0m?mibextid=LQQJ4d&rdid=n10dKSUzqx2b0dII&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FBaVRnjwDtLWAgo4F%2F%3Fmibextid%3DLQQJ4d" 
              className="social-icon facebook" 
              title="Facebook" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
          </div>
      </div>

      <div className="legal-info">
        <h4>Información Legal</h4>
        <ul>
          <li><a href="#">Términos y condiciones</a></li>
          <li><a href="#">Envío y devoluciones</a></li>
          <li><a href="#">Política de privacidad</a></li>
          <li><a href="#">Libro de Reclamaciones</a></li>
        </ul>
      </div>
    </div>

      {/* Derechos Reservados */}
      <div className="copyright">
        <p>Copyright © 2024 Tienda Trending. Creado por Luis Yuu.</p>
        <p>Todos los derechos reservados</p>
      </div>
    </footer>
  );
};

export default Footer;
