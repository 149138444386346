import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import ProductSection from './components/ProductSection';
import NewProduct from './components/NewProduct';
import Banner from './components/Banner';
import Cart from './components/Cart';
import Login from './components/Login';
import Register from './components/Register';
import ProductDetail from './components/ProductDetails';
import ProductCatalog from './components/ProductCatalog';
import ProductFeatures from './components/ProductFeatures';
import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <Navigation />
      <main>
        <Routes>
          <Route path="/" element={
            <>
              <ProductSection />
              <NewProduct />
              <Banner />
              <ProductFeatures/>
            </>
          } />
          <Route path="/carrito" element={<Cart />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route 
                path="/products/:id" 
                element={
                  <>
                    <ProductDetail />
                    <ProductFeatures />
                  </>
                } 
              />
          <Route path="/productCatalog" element={<ProductCatalog/>} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
