import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./NewProduct.css";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const ProductSlider = () => {
  const { user } = useAuth();
  const navigate = useNavigate(); // Para redirigir a detalles del producto
  const [products, setProducts] = useState([]);
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    const storedUserType = localStorage.getItem("tipo_usuario");
    setUserType(storedUserType);

    const token = localStorage.getItem("token");
    const headers = token ? { Authorization: `Bearer ${token}` } : {};

    // Hacer solicitud para obtener solo productos destacados
    axios
      .get("https://tt-backend-34ls.onrender.com/api/best-products", { headers })
      .then((response) => {
        const productsWithImageUrl = response.data.map((product) => ({
            ...product,
            imageUrl: `https://tt-backend-34ls.onrender.com${product.image_path}`,
          }));
        setProducts(productsWithImageUrl); // Asumimos que ya incluye la URL de la imagen
      })
      .catch((error) => {
        console.error("Error al obtener los productos destacados:", error);
      });
  }, []);

  const getPrice = (product) => {
    if (userType === "premium" && product.precio_personalizado) {
      return product.precio_personalizado;
    }
    return product.precio;
  };

  const handleAddToCart = async (productId) => {
    const token = localStorage.getItem("token");
    const cartItem = { productId, quantity: 1 };

    if (!token) {
        // Si no está autenticado, maneja el carrito en localStorage
        let cart = JSON.parse(localStorage.getItem("cart")) || [];
        
        // Busca si ya existe el producto en el carrito
        const existingItem = cart.find((item) => item.productId === productId);

        if (existingItem) {
            existingItem.quantity += 1; // Incrementa la cantidad si ya existe
        } else {
            // Obtener los detalles del producto desde el backend
            try {
                const response = await axios.get(
                    `https://tt-backend-34ls.onrender.com/api/products/${productId}`
                );
                const productDetails = response.data; // Aquí obtienes los detalles del producto (nombre, precio, etc.)
                cart.push({ ...cartItem, ...productDetails }); // Agregar el producto completo con detalles
                localStorage.setItem("cart", JSON.stringify(cart));
                alert("Producto añadido al carrito");
            } catch (error) {
                console.error("Error al obtener los detalles del producto", error);
                alert("Hubo un error al intentar obtener los detalles del producto.");
            }
        }

        return;
    }

    // Si está autenticado, envía la solicitud al backend
    try {
        const response = await axios.post(
            "https://tt-backend-34ls.onrender.com/api/cart",
            cartItem,
            { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.status === 200) {
            console.log("Producto añadido al carrito");
            alert("Producto añadido al carrito.");
        }
    } catch (error) {
        console.error("Error al agregar producto al carrito", error);
        alert("Hubo un error al intentar agregar el producto al carrito.");
    }
};

  const handleViewDetails = (productId) => {
    navigate(`/products/${productId}`);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 4 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <div className="section-NewProduct">
      <div className="container-newProduct">
        <div className="section-title">
          <h3 className="title">Productos Destacados</h3>
        </div>
        <Slider {...sliderSettings}>
          {products.map((product) => (
            <div
              key={product.id_producto}
              className="product-card"
              onClick={() => handleViewDetails(product.id_producto)}
              style={{ cursor: "pointer" }}
            >
              <div className="product-img">
                <img src={product.imageUrl} alt={product.nombre} />
              </div>
              <div className="product-category">Producto</div>
              <div className="product-name">{product.nombre}</div>
              <div className="product-pricing">
                <span className="current-price">S/.{getPrice(product)}</span>
              </div>
              <div className="product-rating">⭐⭐⭐⭐⭐</div>
              <div className="product-actions">
                <button
                  className="add-to-cart-btn"
                  onClick={(e) => {
                    e.stopPropagation(); // Evitar la navegación al hacer clic en el botón
                    handleAddToCart(product.id_producto);
                  }}
                >
                  Agregar al Carrito
                </button>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ProductSlider;
