// Register.js
import React, { useState } from 'react';
import './Auth.css'; // Usa los mismos estilos de Auth.css
import axios from 'axios';

const Register = () => {
  const [nombreUsuario, setNombreUsuario] = useState('');
  const [email, setEmail] = useState('');
  const [contraseña, setContraseña] = useState('');
  const [confirmarContraseña, setConfirmarContraseña] = useState('');
  const [dniORuc, setDniORuc] = useState('');
  const [nombres, setNombres] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [telefono, setTelefono] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (contraseña !== confirmarContraseña) {
      alert('Las contraseñas no coinciden');
      return;
    }

    try {
      // Enviar los datos al backend para el registro
      const response = await axios.post('https://tt-backend-34ls.onrender.com/auth/register', {
        nombre_usuario: nombreUsuario,
        email,
        contraseña,
        dni_o_ruc: dniORuc,
        nombres,
        apellidos,
        telefono,
      });

      if (response.status === 201) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      } else {
        setError(response.data || 'Hubo un error al registrar el usuario');
      }
    } catch (error) {
      console.error('Error al registrar usuario:', error);
      setError('Error al registrar usuario. Inténtalo de nuevo.');
    }
  };

  return (
    <div className="form-container">
      <div className="auth-box">
        <h2 className="title-auth">Registrarse</h2>
        {success && <div className="success-message">¡Registro exitoso! Ahora puedes iniciar sesión.</div>}
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit} className="form">
          <div className="input-group">
            <label htmlFor="nombreUsuario">Nombre de Usuario</label>
            <input
              type="text"
              id="nombreUsuario"
              placeholder="Ingresa tu nombre de usuario"
              value={nombreUsuario}
              onChange={(e) => setNombreUsuario(e.target.value)}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              placeholder="Ingresa tu correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="contraseña">Contraseña</label>
            <input
              type="password"
              id="contraseña"
              placeholder="Ingresa tu contraseña"
              value={contraseña}
              onChange={(e) => setContraseña(e.target.value)}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="confirmarContraseña">Confirmar Contraseña</label>
            <input
              type="password"
              id="confirmarContraseña"
              placeholder="Confirma tu contraseña"
              value={confirmarContraseña}
              onChange={(e) => setConfirmarContraseña(e.target.value)}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="dniORuc">DNI o RUC</label>
            <input
              type="text"
              id="dniORuc"
              placeholder="Ingresa tu DNI o RUC"
              value={dniORuc}
              onChange={(e) => setDniORuc(e.target.value)}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="nombres">Nombres</label>
            <input
              type="text"
              id="nombres"
              placeholder="Ingresa tus nombres"
              value={nombres}
              onChange={(e) => setNombres(e.target.value)}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="apellidos">Apellidos</label>
            <input
              type="text"
              id="apellidos"
              placeholder="Ingresa tus apellidos"
              value={apellidos}
              onChange={(e) => setApellidos(e.target.value)}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="telefono">Teléfono</label>
            <input
              type="text"
              id="telefono"
              placeholder="Ingresa tu teléfono"
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
              required
            />
          </div>

          <button type="submit" className="sign">
            Registrarse
          </button>
        </form>
        <div className="signup">
          <p>¿Ya tienes una cuenta? <a href="/login">Iniciar sesión</a></p>
        </div>
      </div>
    </div>
  );
};

export default Register;
