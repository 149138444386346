import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import './ProductCatalog.css'; // Asegúrate de tener un archivo CSS para los estilos

const ProductCatalog = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    const storedUserType = localStorage.getItem("tipo_usuario");
    setUserType(storedUserType);

    const token = localStorage.getItem("token");
    const headers = token ? { Authorization: `Bearer ${token}` } : {};

    axios
      .get("https://tt-backend-34ls.onrender.com/api/products", { headers })
      .then((response) => {
        const productsWithImageUrl = response.data.map((product) => ({
          ...product,
          imageUrl: `https://tt-backend-34ls.onrender.com${product.image_path}`,
        }));
        setProducts(productsWithImageUrl);
      })
      .catch((error) => {
        console.error("Error al obtener los productos:", error);
      });
  }, []);

  const getPrice = (product) => {
    if (userType === "premium" && product.precio_personalizado) {
      return product.precio_personalizado;
    }
    return product.precio;
  };

  const handleAddToCart = async (productId) => {
    const token = localStorage.getItem("token");
    const cartItem = { productId, quantity: 1 };

    if (!token) {
        // Si no está autenticado, maneja el carrito en localStorage
        let cart = JSON.parse(localStorage.getItem("cart")) || [];
        
        // Busca si ya existe el producto en el carrito
        const existingItem = cart.find((item) => item.productId === productId);

        if (existingItem) {
            existingItem.quantity += 1; // Incrementa la cantidad si ya existe
        } else {
            // Obtener los detalles del producto desde el backend
            try {
                const response = await axios.get(
                    `https://tt-backend-34ls.onrender.com/api/products/${productId}`
                );
                const productDetails = response.data; // Aquí obtienes los detalles del producto (nombre, precio, etc.)
                cart.push({ ...cartItem, ...productDetails }); // Agregar el producto completo con detalles
                localStorage.setItem("cart", JSON.stringify(cart));
                alert("Producto añadido al carrito");
            } catch (error) {
                console.error("Error al obtener los detalles del producto", error);
                alert("Hubo un error al intentar obtener los detalles del producto.");
            }
        }

        return;
    }

    // Si está autenticado, envía la solicitud al backend
    try {
        const response = await axios.post(
            "https://tt-backend-34ls.onrender.com/api/cart",
            cartItem,
            { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.status === 200) {
            console.log("Producto añadido al carrito");
            alert("Producto añadido al carrito.");
        }
    } catch (error) {
        console.error("Error al agregar producto al carrito", error);
        alert("Hubo un error al intentar agregar el producto al carrito.");
    }
};

  const handleViewDetails = (productId) => {
    navigate(`/products/${productId}`);
  };

  return (
    <div className="product-catalog">
      <h2 className="catalog-title">Catálogo de Productos</h2>
      <div className="product-grid-catalog">
        {products.map((product) => (
          <div
            className="product-card-catalog"
            key={product.id_producto}
            onClick={() => handleViewDetails(product.id_producto)}
          >
            <img
              className="product-image-catalog"
              src={product.imageUrl}
              alt={product.nombre}
            />
            <h3 className="product-name-catalog">{product.nombre}</h3>
            <p className="product-category-catalog">Producto</p>
            <p className="product-price-catalog">S/.{getPrice(product)}</p>
            <button
              className="add-to-cart-btn-catalog"
              onClick={(e) => {
                e.stopPropagation();
                handleAddToCart(product.id_producto);
              }}
            >
              Agregar al Carrito
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductCatalog;
