import React, { useEffect } from 'react';
import axios from 'axios';
import "./Banner.css"; // Usa el CSS que ya proporcionaste
import fondo from "../assets/FondoBanner.png"; // Ruta correcta del fondo
import texto from "../assets/ImagenBanner2.png"; // Ruta del texto
import airpods from "../assets/ImagenBanner.png"; // Ruta de los AirPods

const Banner = () => {
  return (
    <div className="banner">
      <img src={fondo} alt="Fondo" className="banner-fondo" />
      <div className="banner-content">
        <img src={texto} alt="Texto AirPods Pro 2" className="banner-texto" />
        <img src={airpods} alt="AirPods" className="banner-airpods" />
      </div>
    </div>
  );
};

export default Banner;
